
$(document).ready(function() {
  function delay(n) {
    n = n || 1000;
    $(".load-container").fadeIn();
    return new Promise((done) => {
        setTimeout(() => {
            done();
            $("main").fadeIn();
            $("header").fadeIn();
            $(".load-container").fadeOut();
            $("[data-aos]").each(function () {
              $(this).addClass("aos-init");
              $(this).addClass("aos-animate");
            });
            VANTA.NET({
              el: "#splash",
              mouseControls: true,
              touchControls: true,
              gyroControls: true,
              minHeight: 200.00,
              minWidth: 200.00,
              scale: 1.00,
              scaleMobile: 1.00,
              color: 0x787878,
              backgroundColor: 0xffffff,
              points: 15.00,
              spacing: 19.00
            })



               
    $('.slk-slider').slick({
      infinite: true,
      slidesToShow:3,
      slidesToScroll:1,
      autoplay:true,
      variableWidth: false,
      verticalSwiping:true,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            infinite: true,
            dots: true
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
        // You can unslick at a given breakpoint now by adding:
        // settings: "unslick"
        // instead of a settings object
      ]
    });
    $('.team-carusel').slick({
      infinite: true,
      slidesToShow: 6,
      slidesToScroll:2,
      autoplay:true,
      variableWidth: true,
      verticalSwiping:true,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: true,
            dots: true
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
        // You can unslick at a given breakpoint now by adding:
        // settings: "unslick"
        // instead of a settings object
      ]
    });


        }, n);
    });
  }
  
  delay(1000);
})
  





// document.load = function () {
//   var state = document.readyState;
//   if (state == "interactive") {
//     document.getElementById(".contents").style.visibility = "hidden";
//   } else if (state == "complete") {
//     setTimeout(function () {
//       document.getElementById("interactive");
//       document.getElementById("load").style.visibility = "hidden";
//       document.getElementById("contents").style.visibility = "visible";
//     }, 1000);
//   }
// };


// function delay(n) {
//   n = n || 2000;
//   return new Promise((done) => {
//       setTimeout(() => {
//           done();
//       }, n);
//   });
// }

// function pageTransition() {
//   var tl = gsap.timeline();
//   tl.to(".loading-screen", {
//       duration: 1.2,
//       width: "100%",
//       left: "0%",
//       ease: "Expo.easeInOut",
//   });

//   tl.to(".loading-screen", {
//       duration: 1,
//       width: "100%",
//       left: "100%",
//       ease: "Expo.easeInOut",
//       delay: 0.3,
//   });
//   tl.set(".loading-screen", { left: "-100%" });
// }

// function contentAnimation() {
//   var tl = gsap.timeline();
//   tl.from(".animate-this", { duration: 1, y: 30, opacity: 0, stagger: 0.4, delay: 0.2 });
// }

// $(function () {
//   barba.init({
//       sync: true,

//       transitions: [
//           {
//               async leave(data) {
//                   const done = this.async();

//                   pageTransition();
//                   await delay(1000);
//                   done();
//               },

//               async enter(data) {
//                   contentAnimation();
//               },

//               async once(data) {
//                   contentAnimation();
//               },
//           },
//       ],
//   });
// });
// const App = () => <h4>Hello, LFD!</h4>;
// ReactDOM.render(<App />, document.getElementById("mydiv"));
$(document).ready(function(){
  
  $('.section').each(function() {
    if ($(this).attr("data-anchor") ==='main-footer') {
      $(this).addClass('main-footer');
    }
 
});
});
var SCROLLING_SPEED = 700;
var servicesfullpage = new fullpage(".services-page", {
  //Navigation

	anchors:['firstPage', 'secondPage'],
	navigation: true,
	navigationPosition: 'right',
	navigationTooltips: ['Our Services', 'Data Science Consulting','Data Auditing','Business Intelligence Services','Data Strategy','Data Architecture','Sales Forecasting','Credit Scoring',''],
	showActiveTooltip: true,
	slidesNavigation: true,
//	slidesNavPosition: 'middle',
  autoScrolling: true,
 
  onLeave: (origin, destination, direction) => {
    
    // if($("nav").hasClass('navigation-wrapper-active')) {
    //   normalScrollElements: '.section';
    //   fullpage_api.setAllowScrolling(false);
 
    // }
    // if($("nav").hasClass('navigation-wrapper-hidden')) {
    //   normalScrollElements: '.section';
    //   fullpage_api.setAllowScrolling(true);
 
    // }

    setTimeout(() => {
      if($('body').hasClass('fp-viewing-main-footer')){
       
        $("body.fp-viewing-main-footer header").css("transition","all ease-in 0.7s");
        $("body.fp-viewing-main-footer header").css("backgroundColor","#fff");
      }
      if(!$('body').hasClass('fp-viewing-main-footer')){
       
        $("body header").css("transition","all ease-in 0.7s");
        $("body header").css("backgroundColor","transparent");
      }
    }, 1000);



    $("[data-aos]").each(function () {
      $(this).addClass("aos-init");
    });
    $(".section [data-aos]").each(function () {
      $(this).removeClass("aos-animate");
    });
  },
  onSlideLeave: function () {
    $(".slide [data-aos]").each(function () {
      $(this).removeClass("aos-animate");
    });
  },
  afterSlideLoad: function () {
    $(".slide.active [data-aos]").each(function () {
      $(this).addClass("aos-animate");
    });
  },
  afterLoad: function () {
    $(".section.active [data-aos]").each(function () {
      $(this).addClass("aos-animate");
    });
  },
  responsiveWidth: 1100,
  afterResponsive: function(isResponsive){
      $("header").addClass("filled-header");
     $("#fullpage").css("padding-top","95px");
  }
 
});

$(document).ready(function(){


var myFullpage = new fullpage("#fullpage", {
  autoScrolling: true,
  verticalCentered: true,
  slidesNavigation: true,
  responsiveWidth: 800,
  css3: false,
  paddingTop: '95px',
  paddingBottom: '50px',
  onLeave: (origin, destination, direction) => {

    const section = destination.item;
    const body = origin.body;
    const tl = new TimelineMax({ delay: 0.7 });
    const title = section.querySelector("h1.titlepage");
    const txtrotate = section.querySelector(".txt-rotate");
    const vdo = section.querySelector("#bgvid1");
    const line1 = section.querySelector(".line1");
    const line2 = section.querySelector(".line2");
    const line3 = section.querySelector(".line3");
    const links0 = section.querySelectorAll(".menu-slide a");
    tl.fromTo(vdo, 0.5, { y: "0", opacity: 0 }, { y: 0, opacity: 1 });
    tl.fromTo(title, 1,{ y: "-5%", opacity: 0, ease: Power4.easeOut },{ y: "0%", opacity: 1 });
    tl.fromTo(txtrotate, 1,{ y: "-5%", opacity: 0, ease: Power4.easeOut },{ y: "0%", opacity: 1 });
    // tl.fromTo(line1,0.5,{x:'0', width:0},{x:0,width:"100%"})
    // tl.fromTo(line2,0.5,{x:'0', width:0},{x:0,width:"100%"})
    // tl.fromTo(line3,0.5,{y:'0', height:0},{y:0,height:"100%"})
    tl.fromTo(links0, 0.5, { y: "3", opacity: 0 }, { y: 0, opacity: 1 });
    tl.from(line1, 1, { scaleX: 0, transformOrigin: "center" }, 0);
    tl.from(line2, 1, { scaleX: 0, transformOrigin: "center" }, 0);
    tl.from(line3, 1, { scaleY: 0, transformOrigin: "center" }, 0);
    setTimeout(() => {
      if($('body').hasClass('fp-viewing-main-footer')){
       
        $("body.fp-viewing-main-footer header").css("transition","all ease-in 0.7s");
        $("body.fp-viewing-main-footer header").css("backgroundColor","#fff");
      }
      if(!$('body').hasClass('fp-viewing-main-footer')){
       
        $("body header").css("transition","all ease-in 0.7s");
        $("body header").css("backgroundColor","transparent");
      }
    }, 1000);
    $("[data-aos]").each(function () {
      $(this).addClass("aos-init");
    });
    $(".section [data-aos]").each(function () {
      $(this).removeClass("aos-animate");
    });
  },

  onSlideLeave: function () {
    $(".slide [data-aos]").each(function () {
      $(this).removeClass("aos-animate");
    });
  },
  afterSlideLoad: function () {
    $(".slide.active [data-aos]").each(function () {
      $(this).addClass("aos-animate");
    });
  },
  afterLoad: function () {
    $(".section.active [data-aos]").each(function () {
      $(this).addClass("aos-animate");
    });
  },
  responsiveWidth: 1100,
  afterResponsive: function(isResponsive){
    AOS.init({
      disable: function() {
        var maxWidth = 1100;
        return window.innerWidth < maxWidth;
      }
    });
    $("header").addClass("filled-header");
    $("#fullpage").css("padding-top","95px");
  }
});
});
var myFullpage = new fullpage("#homefullpage", {
  autoScrolling: true,
  verticalCentered: true,
  slidesNavigation: true,
  responsiveWidth: 800,
  css3: false,
  anchors: ['screenhome', 'homenavigation', 'homefooter'],
  onLeave: (origin, destination, direction) => {
    const section = destination.item;
    const body = origin.body;
    const tl = new TimelineMax({ delay: 0.7 });
    const title = section.querySelector("h1.titlepage");
    const vdo = section.querySelector("#bgvid1");
    const line1 = section.querySelector(".line1");
    const line2 = section.querySelector(".line2");
    const line3 = section.querySelector(".line3");
    const links0 = section.querySelectorAll(".menu-slide a");
    tl.fromTo(vdo, 0.5, { y: "0", opacity: 0 }, { y: 0, opacity: 1 });
    tl.fromTo(
      title,
      1,
      { y: "-5%", opacity: 0, ease: Power4.easeOut },
      { y: "0%", opacity: 1 }
    );
    // tl.fromTo(line1,0.5,{x:'0', width:0},{x:0,width:"100%"})
    // tl.fromTo(line2,0.5,{x:'0', width:0},{x:0,width:"100%"})
    // tl.fromTo(line3,0.5,{y:'0', height:0},{y:0,height:"100%"})
    tl.fromTo(links0, 0.5, { y: "3", opacity: 0 }, { y: 0, opacity: 1 });
    tl.from(line1, 1, { scaleX: 0, transformOrigin: "center" }, 0);
    tl.from(line2, 1, { scaleX: 0, transformOrigin: "center" }, 0);
    tl.from(line3, 1, { scaleY: 0, transformOrigin: "center" }, 0);

    $("[data-aos]").each(function () {
      $(this).addClass("aos-init");
    });
    $(".section [data-aos]").each(function () {
      $(this).removeClass("aos-animate");
    });
  },

  onSlideLeave: function () {
    $(".slide [data-aos]").each(function () {
      $(this).removeClass("aos-animate");
    });
  },
  afterSlideLoad: function () {
    $(".slide.active [data-aos]").each(function () {
      $(this).addClass("aos-animate");
    });
    
  },
  afterLoad: function () {
    $(".section.active [data-aos]").each(function () {
      $(this).addClass("aos-animate");
    });


  },
  
});

$('.menu-links a').click(function(){
 if($('div').is('#fullpage')){
  fullpage_api.setAllowScrolling(true);
  
 }
 if (window.matchMedia('(max-width: 767px)').matches) {
  $('body').css("overflow",'auto');
  $(".content-area a").css("pointer-events","auto");
}
 if (window.matchMedia('(min-width: 768px)').matches) {
  $(".content-area a").css("pointer-events","none");
  $('body').css("overflow",'hidden');
}

  $('.menu.menu-active').removeClass('menu-active');
  $('.navigation-wrapper').removeClass('navigation-wrapper-active');
  $('.navigation-wrapper').addClass('navigation-wrapper-hidden');
  
})

// gsap.registerPlugin(ScrollTrigger);
// const logo = document.querySelector(".lfd-logo img");
// gsap.to(logo, {
//   scrollTrigger: {
//     trigger: logo,
//     start: "0 100%",
//     markers: false,
//     scrub: true,
//     onUpdate: (self) => {
//       console.log(self.progress);
//     },
//     toggleActions: "play pause reverse play",
//   },
//   y: 400,
//   x: -900,
//   rotation: 90,
//   duration: 1,
// });


























AOS.init({
  // Global settings:

  disable: false, // accepts following values: 'phone', 'tablet', 'mobile', boolean, expression or function
  startEvent: "DOMContentLoaded", // name of the event dispatched on the document, that AOS should initialize on
  initClassName: "aos-init", // class applied after initialization
  animatedClassName: "aos-animate", // class applied on animation
  useClassNames: false, // if true, will add content of `data-aos` as classes on scroll
  disableMutationObserver: false, // disables automatic mutations' detections (advanced)
  debounceDelay: 50, // the delay on debounce used while resizing window (advanced)
  throttleDelay: 99, // the delay on throttle used while scrolling the page (advanced)

  // Settings that can be overridden on per-element basis, by `data-aos-*` attributes:
  offset: 120, // offset (in px) from the original trigger point
  delay: 0, // values from 0 to 3000, with step 50ms
  duration: 400, // values from 0 to 3000, with step 50ms
  easing: "ease", // default easing for AOS animations
  once: false, // whether animation should happen only once - while scrolling down
  mirror: false, // whether elements should animate out while scrolling past them
  anchorPlacement: "top-bottom", // defines which position of the element regarding to window should trigger the animation
  disable: function() {
    var maxWidth = 800;
    return window.innerWidth < maxWidth;
  }
});


(function () {
  function triggerLines() {
    var tl = new TimelineLite();

    TweenMax.set("#lineLeft", { marginTop: 50, marginLeft: 20 });
    TweenMax.set("#line2", { marginTop: 50, marginLeft: 180 });

    // tl.addLabel("start");
    tl.from("#line1", 1, { scaleX: 0, transformOrigin: "right" }, 0);
    tl.from("#line2", 1, { width: 0, scaleX: 0, transformOrigin: "left" }, 0);
  }
  var tl1 = new TimelineMax({ delay: 0 });
  //var tl = new TimelineLite()

  //	TweenMax.set('#lineLeft', {marginTop:50, marginLeft:20});
  //	TweenMax.set('#line2', {marginTop:50, marginLeft:180});
  // tl.addLabel("start");

  tl1
    .to("#one", 0.5, { opacity: 1 })
    .from("#one .text1", 0.5, {
      left: "-100%",
      opacity: 0,
      ease: Power1.easeIn,
    })
    .to(
      "#one .text1",
      0.5,
      { left: "100%", opacity: 0, ease: Power1.easeOut },
      "+=1"
    )
    .to("#one", 0.1, { opacity: 0, onComplete: triggerLines }, "+=0.25")

    // starts banner second frame
    .to("#two", 0.1, { opacity: 1 })
    .to("#two .text2", 1, { ease: Power1.easeIn });
})();

var TxtRotate = function (el, toRotate, period) {
  this.toRotate = toRotate;
  this.el = el;
  this.loopNum = 0;
  this.period = parseInt(period, 10) || 2000;
  this.txt = "";
  this.tick();
  this.isDeleting = false;
};

TxtRotate.prototype.tick = function () {
  var i = this.loopNum % this.toRotate.length;
  var fullTxt = this.toRotate[i];

  if (this.isDeleting) {
    this.txt = fullTxt.substring(0, this.txt.length - 1);
  } else {
    this.txt = fullTxt.substring(0, this.txt.length + 1);
  }

  this.el.innerHTML = '<span class="wrap">' + this.txt + "</span>";

  var that = this;
  var delta = 190 - Math.random() * 100;

  if (this.isDeleting) {
    delta /= 2;
  }

  if (!this.isDeleting && this.txt === fullTxt) {
    delta = this.period;
    this.isDeleting = true;
  } else if (this.isDeleting && this.txt === "") {
    this.isDeleting = false;
    this.loopNum++;
    delta = 500;
  }

  setTimeout(function () {
    that.tick();
  }, delta);
};

window.onload = function () {
  var elements = document.getElementsByClassName("txt-rotate");

  for (var i = 0; i < elements.length; i++) {
    var toRotate = elements[i].getAttribute("data-rotate");
    var period = elements[i].getAttribute("data-period");
    if (toRotate) {
      new TxtRotate(elements[i], JSON.parse(toRotate), period);
    }
  }
  // INJECT CSS
  var css = document.createElement("style");

  css.innerHTML = ".txt-rotate > .wrap { border-right: .05em solid #a19e9e;  }";
  document.body.appendChild(css);
};

// Utility function
function Util() {}

/* 
	class manipulation functions
*/
Util.hasClass = function (el, className) {
  if (el.classList) return el.classList.contains(className);
  else
    return !!el.className.match(new RegExp("(\\s|^)" + className + "(\\s|$)"));
};

Util.addClass = function (el, className) {
  var classList = className.split(" ");
  if (el.classList) el.classList.add(classList[0]);
  else if (!Util.hasClass(el, classList[0])) el.className += " " + classList[0];
  if (classList.length > 1) Util.addClass(el, classList.slice(1).join(" "));
};

Util.removeClass = function (el, className) {
  var classList = className.split(" ");
  if (el.classList) el.classList.remove(classList[0]);
  else if (Util.hasClass(el, classList[0])) {
    var reg = new RegExp("(\\s|^)" + classList[0] + "(\\s|$)");
    el.className = el.className.replace(reg, " ");
  }
  if (classList.length > 1) Util.removeClass(el, classList.slice(1).join(" "));
};

Util.toggleClass = function (el, className, bool) {
  if (bool) Util.addClass(el, className);
  else Util.removeClass(el, className);
};

Util.setAttributes = function (el, attrs) {
  for (var key in attrs) {
    el.setAttribute(key, attrs[key]);
  }
};

/* 
  DOM manipulation
*/
Util.getChildrenByClassName = function (el, className) {
  var children = el.children,
    childrenByClass = [];
  for (var i = 0; i < el.children.length; i++) {
    if (Util.hasClass(el.children[i], className))
      childrenByClass.push(el.children[i]);
  }
  return childrenByClass;
};

/* 
	Animate height of an element
*/
Util.setHeight = function (start, to, element, duration, cb) {
  var change = to - start,
    currentTime = null;

  var animateHeight = function (timestamp) {
    if (!currentTime) currentTime = timestamp;
    var progress = timestamp - currentTime;
    var val = parseInt((progress / duration) * change + start);
    element.setAttribute("style", "height:" + val + "px;");
    if (progress < duration) {
      window.requestAnimationFrame(animateHeight);
    } else {
      cb();
    }
  };

  //set the height of the element before starting animation -> fix bug on Safari
  element.setAttribute("style", "height:" + start + "px;");
  window.requestAnimationFrame(animateHeight);
};
// var myElement = document.getElementById("nofullpage");
// if(myElement){
//   // Element exists
//   console.log(myElement);
//             var v = document.getElementsById("barba-wrapper");
//             console.log(v);
//             v.className += "nofullpage";
//             v.className += 'responsive';
//             console.log("Azhar Raza");
// }

// var bodytag = document.getElementsByTagName("body");
// console.log(v);
// console.log( myElement);
// v.className += "nofullpage";
/* 
	Smooth Scroll
*/


Util.scrollTo = function (final, duration, cb) {
  var start = window.scrollY || document.documentElement.scrollTop,
    currentTime = null;

  var animateScroll = function (timestamp) {
    if (!currentTime) currentTime = timestamp;
    var progress = timestamp - currentTime;
    if (progress > duration) progress = duration;
    var val = Math.easeInOutQuad(progress, start, final - start, duration);
    window.scrollTo(0, val);
    if (progress < duration) {
      window.requestAnimationFrame(animateScroll);
    } else {
      cb && cb();
    }
  };

  window.requestAnimationFrame(animateScroll);
};

/* 
  Focus utility classes
*/

//Move focus to an element
Util.moveFocus = function (element) {
  if (!element) element = document.getElementsByTagName("body")[0];
  element.focus();
  if (document.activeElement !== element) {
    element.setAttribute("tabindex", "-1");
    element.focus();
  }
};

/* 
  Misc
*/

Util.getIndexInArray = function (array, el) {
  return Array.prototype.indexOf.call(array, el);
};

Util.cssSupports = function (property, value) {
  if ("CSS" in window) {
    return CSS.supports(property, value);
  } else {
    var jsProperty = property.replace(/-([a-z])/g, function (g) {
      return g[1].toUpperCase();
    });
    return jsProperty in document.body.style;
  }
};

/* 
	Polyfills
*/
//Closest() method
// if (!Element.prototype.matches) {
//   Element.prototype.matches =
//     Element.prototype.msMatchesSelector ||
//     Element.prototype.webkitMatchesSelector;
// }

// if (!Element.prototype.closest) {
//   Element.prototype.closest = function (s) {
//     var el = this;
//     if (!document.documentElement.contains(el)) return null;
//     do {
//       if (el.matches(s)) return el;
//       el = el.parentElement || el.parentNode;
//     } while (el !== null && el.nodeType === 1);
//     return null;
//   };
// }

// //Custom Event() constructor
// if (typeof window.CustomEvent !== "function") {
//   function CustomEvent(event, params) {
//     params = params || { bubbles: false, cancelable: false, detail: undefined };
//     var evt = document.createEvent("CustomEvent");
//     evt.initCustomEvent(
//       event,
//       params.bubbles,
//       params.cancelable,
//       params.detail
//     );
//     return evt;
//   }

//   CustomEvent.prototype = window.Event.prototype;

//   window.CustomEvent = CustomEvent;
// }




// function pageTransition(){
 
//     var tl = gsap.timeline();
//     tl.to('.lfd-logo', {dusration:.5,scaleY:1,transformOrigin:"bottom left"})
//     tl.to('.lfd-logo', {dusration:.5,scaleY:0,transformOrigin:"bottom left"})
// }
// function contentAnimation(){
//   var tl = gsap.timeline();
//   tl.from('.section',{dusration:0.5,translateY:50, opacity:0})
//   tl.to('img',{clipPath:"polygon(0 0, 100% 0,100% 100%,0% 100%)"})
// };
// function delay(n){
//   n= n || 2000;
//   return new Promise(done => {
//     setTimeout(() => {
//       done();
//     },n)
//   })
// }



















// function pageTransition(){
//   barba.use(barbaCss);
//     var tl = gsap.timeline();
//     tl.to('.lfd-logo', {dusration:.5,scaleY:1,transformOrigin:"bottom left"})
//     tl.to('.lfd-logo', {dusration:.5,scaleY:0,transformOrigin:"bottom left"})
// }
// function contentAnimation(){
//   var tl = gsap.timeline();
//   tl.from('.section',{dusration:0.5,translateY:50, opacity:0})
//   tl.to('img',{clippath:"polygon(0 0, 100% 0,100% 100%,0% 100%)"})
// };
// function delay(n){
//   n= n || 2000;
//   return new Promise(done => {
//     setTimeout(() => {
//       done();
//     },n)
//   })
// }
// barba.init({
//   sync:true,
//     transitions:[{
//       async leave(data){
//         pageTransition();
//         await delay(1500);
//         done();
//       },
//       async enter(data){
//         contentAnimation();
//       },
//       async once(data){
//         contentAnimation();
//       }
//     }],
//     requestError: (trigger, action, url, response) => {
//       // go to a custom 404 page if the user click on a link that return a 404 response status
//       if (action === 'click' && response.status && response.status === 404) {
//         barba.go('/404');
//       }
  
//       // prevent Barba from redirecting the user to the requested URL
//       // this is equivalent to e.preventDefault()
//       return false;
//     },
// });

$(".nav-navigation >ul li.about-us-submenu").click(
  function(){
    var azhar = $(this).find(".container").length;

    $(".nav-navigation >ul li").removeClass("active");
    $(this).addClass("active");  
   
    $( ".full-menu" ).each(function() {
      $( ".full-menu" ).removeClass( "active" );
      $( ".about-us-submenu" ).addClass( "active" );
      $( ".nav-navigation >ul li" ).removeClass( "active" );
      $( ".nav-navigation >ul li.about-us-submenu" ).addClass( "active" );
    });
  }
  
);
$(".nav-navigation >ul li.succses-stories-submenu").click(
  function(){
    var azhar = $(this).find(".container").length;

    $(".nav-navigation >ul li").removeClass("active");
    $(this).addClass("active");  
    $( ".about-us-submenu" ).addClass( "active" );
   
    $( ".full-menu" ).each(function() {
      $( ".full-menu" ).removeClass( "active" );
      $( ".succses-stories-submenu" ).addClass( "active" );
      $( ".nav-navigation >ul li" ).removeClass( "active" );
      $( ".nav-navigation >ul li.succses-stories-submenu" ).addClass( "active" );
    });
  }
  
);

$('.menu').click(function()
{
 
  $('header').toggleClass("notfilled");
  $(".nav-navigation > ul li").filter(function() {
    if($(this).find(".container").length !== 0){
      $(this).addClass('has-children');
     
    }; 
    
  });
  $(".nav-navigation >ul li").click(function(){
      $(this).find("active").toggleClass("active");
  })
     $('.navigation-wrapper').toggleClass('navigation-wrapper-hidden navigation-wrapper-active'); //Adds 'a', removes 'b' and vice versa
     $('.menu').toggleClass('menu-active'); //Adds 'a', removes 'b' and vice versa
      // if($('.menu').hasClass('menu-active')){
      //   $( ".full-menu.about-us-submenu" ).addClass( "active" );
      // } else {
      //   $( " .full-menu.succses-stories-submenu " ).click(function(){
      //     $( ".full-menu.about-us-submenu" ).removeClass( "active" );
      //   })
      // }
      if($('nav').hasClass('navigation-wrapper-active')){
        $("body.fp-viewing-main-footer header").css("transition","all 0.7s ease-in");
        $("body.fp-viewing-main-footer header").css("backgroundColor","transparent");
      }else{
        setTimeout(() => {
          $("body.fp-viewing-main-footer header").css("backgroundColor","#fff");
        }, 2000);
        
      }
      if($("nav").hasClass('navigation-wrapper-hidden')) {
        
        $('.logo').addClass('opacity-0');
        $('.logo').addClass('opacity-1');
        $('body').css("overflow",'initial');
        
    
        if($("div").is("#homefullpage")){
          fullpage_api.setAllowScrolling(true);
         
        } 
        if($("div").is("#fullpage")){
          fullpage_api.setAllowScrolling(true);
         
        } 
        //const links = $(".nav-navigation > ul li a span");
        // tl.fromTo(
        //   title,
        //   1,
        //   {x: "0", opacity: 1, ease: Circ.easeInOut,stagger:0.1},
        //   {x: "-10%", opacity: 0, ease: Circ.easeInOut,stagger:0.1}
        // );
        // tl.fromTo(
        //   links,
        //   1,
        //   {y: "-0%", delay:0, opacity: 1, ease: Circ.easeInOut,stagger:0.1  },
        //   {y: "-10", delay:0, opacity: 0, ease: Circ.easeInOut,stagger:0.1  }
         
        // );
      }

      if($("nav").hasClass('navigation-wrapper-active'))  {
        $('body').css("overflow",'hidden');
        $('.logo').addClass('opacity-0');
        $('.logo').removeClass('opacity-1');
       
        if($("div").is("#homefullpage")){
          fullpage_api.setAllowScrolling(false);
        }
        if($("div").is("#fullpage")){
          fullpage_api.setAllowScrolling(false);
        }
        
      
        // const tl = new TimelineMax({ delay:0.1});
        // const title = $(".nav-navigation");
        // const links = $(".nav-navigation  ul > li a span");
        
        // tl.fromTo(
        //   title,
        //   1,
        //   {x: "-10%", opacity: 0, ease: Circ.easeInOut,stagger:0.1  },
        //   {x: "0", opacity: 1, ease: Circ.easeInOut,stagger:0.1  }
         
        // );
        // tl.fromTo(
        //   links,
        //   1,
        //   {y: "-10%", delay:0, opacity: 0, ease: Circ.easeInOut,stagger:0.1 },
        //   {y: "0", delay:0, opacity: 1, ease: Circ.easeInOut,stagger:0.1  }
         
        // );
      }
    });

    // const swup = new Swup(); 

   $(document).ready(function(){
    
    AOS.init();
    });

    $(".executive").each(function() {
      $(this).click(function(){
       
        $('nav.links-top').addClass("active");
        event.preventDefault();
        $(".executive").removeClass("active");
        $(this).addClass("active");
        if ($(this).hasClass("active")) {
           
            var memberName = $(this).attr('data-member');

           
           
            $('.member-fullpage').each(function() {
              if ($(this).attr("data-member") === memberName) {
                // TODO: enter your code...
                $(".member-fullpage").removeClass("active");
                $(this).addClass("active");
                var reverse = "0%";

                function pageTransition() {
                  var tl = gsap.timeline({delay:0});
                  if($(".member-fullpage").hasClass("active")){

                    tl.to('nav.links-top',{
                    
                      duration:0,
                      delay:0,
                      
                     opacity:"1",
                      ease: "Expo.easeInOut",
                    })
                }
                var tl2 = gsap.timeline({delay:0});
                  
                  tl2.to('.member-fullpage.active', {
                      delay:0,
                      duration: 1.2,
                      width: "100%",
                      left: "0%",
                      x:reverse,
                     opacity:"1",
                      ease: "Power4.easeInOut",
                      function(){
                        $(".close").click(function(){
                          
                          tl2.reverse();
                          // $('nav.links-top').css("opacity",0,'!important');
                          $('nav.links-top').removeClass("active");
                        })
                      }
                  })};
                  pageTransition(reverse);
                 
            } 
            });
            if($('.member-fullpage').attr('data-member') == memberName){
             
            } else{
             
            }
        }
        


     
      });
 
    });


    if ($(".content > div").is("#nofullpage")){
      $("header").addClass("filled-header");
    }
    if ($(".content > div").is("#fullpage")){
      if (window.matchMedia('(max-width: 767px)').matches) {
        $("header").addClass("filled-header");
      }
     
    }
    var footerHeight = $('footer').innerHeight();
   

    
var myFullpage = new fullpage("#aboutus", {
  
  autoScrolling: true,
  verticalCentered: true,
  slidesNavigation: true,
  responsiveWidth: 1100,
  css3: false,
  paddingTop: '0px',
  paddingBottom: '00px',
 
 
    
 
  
  onLeave: (index,newIndex,origin, destination, direction) => {
    
    var params = {
      origin: origin,
      destination:destination,
      direction: direction,
      index:index,
      newIndex:newIndex,
    };
    
 
    
  
    if (index == 3 && direction == 'down'){
      $('.section').eq(index -1).removeClass('moveDown').addClass('moveUp');
    }
    else if(index == 3 && direction == 'up'){
      $('.section').eq(index -1).removeClass('moveUp').addClass('moveDown');
    }

    $('#staticImg').toggleClass('active', (index == 2 && direction == 'down' ) || (index == 4 && direction == 'up'));
    $('#staticImg').toggleClass('moveDown', newIndex == 4);
     $('#staticImg').toggleClass('moveUp', index == 4 && direction == 'up');




    $("[data-aos]").each(function () {
      $(this).addClass("aos-init");
    });
    $(".section [data-aos]").each(function () {
      $(this).removeClass("aos-animate");
    });




  },

  onSlideLeave: function () {
    var leavingSlide = $(this);

       
    $(".slide [data-aos]").each(function () {
      $(this).removeClass("aos-animate");
    });
  },
  afterSlideLoad: function () {
    alert("azhar");
    $(".slide.active [data-aos]").each(function () {
      $(this).addClass("aos-animate");
     
       
       
    });
  },
  afterLoad: function (index,newIndex,destination,direction) {
    if (index == 3 && direction == 'up'){
      $('.section').eq(newIndex.index -1).removeClass('moveDown').addClass('moveUp');
    }
    else if(index == 3 && direction == 'down'){
      $('.section').eq(newIndex.index -1).removeClass('moveUp').addClass('moveDown');
    }
    $(".section.active [data-aos]").each(function () {
      $(this).addClass("aos-animate");
    });
  },
  responsiveWidth: 1100,
  afterResponsive: function(isResponsive){
    paddingTop: '95px',
    AOS.init({
      disable: function() {
        var maxWidth = 1100;
        return window.innerWidth < maxWidth;
      }
    });

    
  

   



  }
});



$(document).ready(function() {
  
  
});


// $(document).ready(function() {
//   $('#aboutus').fullpage({
//     'verticalCentered': false,
//     'css3': true,
//     'sectionsColor': ['#F0F2F4', '#fff', '#fff', '#fff'],
//     'navigation': false,
    

//     afterLoad: function(anchorLink, index){
//       if(index == 2){
//         $('#iphone3, #iphone2, #iphone4').addClass('active');
//       }
//       $('#infoMenu').toggleClass('whiteLinks', index ==4);

//     },

//     onLeave: function(index, newIndex, direction){
//       if (index == 1 && direction == 'down'){
//         $('.section').eq(index -1).removeClass('moveDown').addClass('moveUp');
//       }
//       else if(index == 3 && direction == 'up'){
//         $('.section').eq(index -1).removeClass('moveUp').addClass('moveDown');
//       }

//       $('#staticImg').toggleClass('active', (index == 2 && direction == 'down' ) || (index == 4 && direction == 'up'));
//       $('#staticImg').toggleClass('moveDown', newIndex == 4);
//        $('#staticImg').toggleClass('moveUp', index == 4 && direction == 'up');
//     },

//     /*
//     Needed to maintain the fixed position of the element
//     due to problems using translate3d and fixed elements.
//     */
//     afterRender: function(){
//       $('#infoMenu').appendTo('body');

//       $('#githubLink, .twitter-share-button').appendTo('body');
//     }

//   });
// });

$(document).ready(function () {
  


  if($("body").hasClass("services-lottie")){

    const player = document.getElementById("data-arch");
    console.log(player)
    player.load("./asset/js/1.json"
    );
    const player2 = document.getElementById("datascience");
    player2.load("./asset/js/2.json"
    );
    const player3 = document.getElementById("businessintell");
    player3.load("./asset/js/3.json");
    
    const player4 = document.getElementById("forecasting");
    player4.load("./asset/js/4.json");
    
    const player9 = document.getElementById("data-str");
    console.log(player9);
    player9.load("https://assets2.lottiefiles.com/packages/lf20_gjtbvdu9.json");
    
    const player5 = document.getElementById("credit");
    player5.load("./asset/js/5.json");
  }
  
  if($("body").hasClass("innvation-lottie")){
    
    const player6 = document.getElementById("innovation1");
    console.log(player6);
    player6.load("./asset/js/6.json");


    const player7 = document.getElementById("innovation2");
    console.log(player7);
    player7.load("./asset/js/7.json");
  }
  
  if($("body").hasClass("open-data-lottie")){
    
    const player8 = document.getElementById("open-data1");
    console.log(player8);
    player8.load("./asset/js/8.json");


  }
  if($("body").hasClass("lottie-loading")){
    
    const player10 = document.getElementById("loader");
    console.log(player10);
    player10.load("./asset/js/loading.json");


  }
  
  
});


